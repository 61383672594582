export default {
    primaryKey: 'material_id',
    model: 'material',
    order: 'material_id',
    sort: 'asc',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'material_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'WarehouseDatatableTDMaterialTitle',
        },
        {
            id: 'barcode',
            title: 'barcode',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'artikul',
            title: 'artikul',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'price',
            title: 'price',
            table: true,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'material_group_id',
            title: 'group',
            table: true,
            table_show: 'group.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                }
            }
        },
        {
            id: 'unit_id',
            title: 'unit_of_measure',
            table: true,
            table_show: 'unit.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                required: true
            }
        }
    ]
}
